<template>
  <div class="analysisForm outline-form">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="92px"
    >
      <!-- 公司车队： -->
      <el-form-item label="公司车队：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：">
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
      <!-- 选择时间： -->
      <!-- <el-form-item label="选择时间：" prop="time">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item> -->
      <!-- 离线时间： -->
      <el-form-item label="离线时间：">
        <el-row>
          <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="4">大于</el-col>
          <el-col :xs="8" :sm="8" :md="12" :lg="12" :xl="10">
            <el-input
              v-model="form.outline"
              name="numPicker"
              placeholder="请输入"
            ></el-input>
          </el-col>
          <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="4">分钟</el-col>
        </el-row>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" size="small" @click="onSearch()"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import {
  getRangeDay,
  formatDate,
  formatDay,
  getStartDate,
  checkTimeLimitDay
} from '@/common/utils/index'
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    timeLimit: {
      type: Number,
      default: 60
    },
    formatDay: {
      type: Boolean,
      default: false
    }
  },

  data() {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (
        new Date(getStartDate()) <= value[0] ||
        new Date(getStartDate()) <= value[1]
      ) {
        callback(new Error('不能选择今天及未来时间'))
      } else {
        callback()
      }
    }
    const validateDate1 = (rule, value, callback) => {
      if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`))
      } else {
        callback()
      }
    }
    return {
      terminalNos: [],
      form: {
        time: getRangeDay(),
        outline: 10,
        vehicleIdList: [],
        companyIdList: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近两个月',
            onClick(picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 59
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        time: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ]
      }
    }
  },

  methods: {
    // 获取车组数据
    getGroupIds(val) {
      this.$refs.form.clearValidate()
      this.form.companyIdList = val.length === 0 ? [] : [...val]
    },
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.form.clearValidate()
      this.form.vehicleIdList = [...val]
    },

    onSearch() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {}
          if (this.formatDay) {
            data = {
              ...this.form,
              beginTime: formatDay(this.form.time[0]),
              endTime: formatDay(this.form.time[1])
            }
          } else {
            data = {
              ...this.form,
              beginTime: formatDate(this.form.time[0]),
              endTime: formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59'
            }
          }
          delete data.time
          this.$emit('getFormVal', data)
        }
      })
    }
  },
  mounted() {}
}
</script>
