import { render, staticRenderFns } from "./outlineStatistics.vue?vue&type=template&id=1661bb14&scoped=true&"
import script from "./outlineStatistics.vue?vue&type=script&lang=js&"
export * from "./outlineStatistics.vue?vue&type=script&lang=js&"
import style0 from "./outlineStatistics.vue?vue&type=style&index=0&id=1661bb14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1661bb14",
  null
  
)

export default component.exports